import { FacilityActions, OrganizationActions } from "../utils/actions";
import { requirePermission } from "../utils/auth";
import finance from "../assets/finance.png";

const FINANCE_ROUTES = {
  path: "/finance",
  name: "finance",
  component: { render: h => h("router-view") },
  redirect: "/finance/financeBail",
  meta: { title: "财务管理", icon: finance, showInMenu: true },
  children: [
    {
      path: "/finance/financeOperateFee",
      name: "financeOperateFee",
      component: () => import("@/views/finance/financeOperateFee"),
      meta: {
        title: "经营费用账单",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.OPEX_BILL_READ)
      }
    },
    {
      path: "/finance/financeRent",
      name: "financeRent",
      component: () => import("@/views/finance/financeRent"),
      meta: {
        title: "房租账单",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.RENT_BILL_READ)
      }
    },
    {
      path: "/finance/settlementSheet",
      name: "settlementSheet",
      component: () => import("@/views/finance/settlementSheet"),
      meta: {
        title: "离场结算账单",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.TERMINATION_BILL_READ
        )
      }
    },
    {
      path: "/finance/settlementSheet/settlementDetailView",
      name: "settlementDetailView",
      component: () => import("@/views/finance/settlementDetail/settlementDetail"),
      meta: {
        title: "账单详情",
        authorizationConfig: requirePermission(FacilityActions.TERMINATION_BILL_READ)
      }
    },
    {
      path: "/finance/settlementSheet/settlementDetailEdit",
      name: "settlementDetailEdit",
      component: () => import("@/views/finance/settlementDetail/settlementDetail"),
      meta: {
        title: "账单调整",
        authorizationConfig: requirePermission(FacilityActions.TERMINATION_BILL_ADJUST)
      }
    },
    {
      path: "/finance/financeBail",
      name: "financeBail",
      component: () => import("@/views/finance/financeBail"),
      meta: {
        title: "客户账单",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(OrganizationActions.BILL_READ)
      }
    },
    {
      path: "/finance/financeBail/financeBailDetail",
      name: "financeBailDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/financeBailDetail"),
      meta: {
        title: "账单明细",
        authorizationConfig: requirePermission(OrganizationActions.BILL_READ)
      }
    },
    {
      path: "/finance/financeBail/finSettlementDetail",
      name: "finSettlementDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/finSettlementDetail"),
      meta: {
        title: "离场结算详情",
        authorizationConfig: requirePermission(
          FacilityActions.TERMINATION_BILL_READ
        )
      }
    },
    {
      path: "/finance/financeBail/finOperateDetail",
      name: "finOperateDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/finOperateDetail"),
      meta: {
        title: "经营费用详情",
        authorizationConfig: requirePermission(FacilityActions.OPEX_BILL_READ)
      }
    },
    {
      path: "/finance/financeBail/finRentDetail",
      name: "finRentDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/finRentDetail"),
      meta: {
        title: "房租账单详情",
        authorizationConfig: requirePermission(FacilityActions.RENT_BILL_READ)
      }
    },
    {
      path: "/finance/financeBail/finDownPaymentDetail",
      name: "finDownPaymentDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/finDownPaymentDetail"),
      meta: {
        title: "首期款账单明细",
        authorizationConfig: requirePermission(FacilityActions.DOWN_BILL_READ)
      }
    },
    {
      path: "/finance/financeBail/finBuildDetail",
      name: "finBuildDetail",
      component: () =>
        import("@/views/finance/financeBailDetail/finBuildDetail"),
      meta: {
        title: "改造费账单",
        authorizationConfig: requirePermission(FacilityActions.BUILD_BILL_READ)
      }
    },
    {
      path: "/finance/finContractParty",
      name: "finContractParty",
      component: () => import("@/views/finance/finContractParty"),
      meta: {
        title: "税点配置",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.LEGAL_ENTITY_READ
        )
      }
    },
    {
      path: "/finance/companyMainConfig",
      name: "companyMainConfig",
      component: () => import("@/views/finance/companyMainConfig"),
      meta: {
        title: "公司主体配置",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.PAYMENT_CHANNEL_READ
        )
      }
    },
    {
      path: "/finance/contractSubjectConfig",
      name: "contractSubjectConfig",
      component: () => import("@/views/finance/contractSubjectConfig"),
      meta: {
        title: "门店签约主体配置",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.PAYMENT_CHANNEL_READ
        )
      }
    },
    {
      path: "/finance/financePayment",
      name: "financePayment",
      component: () => import("@/views/finance/financePayment"),
      meta: {
        title: "支付配置管理",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.PAYMENT_CHANNEL_READ
        )
      }
    },
    {
      path: "/finance/finExportExcel",
      name: "finExportExcel",
      component: () => import("@/views/finance/finExportExcel"),
      meta: {
        title: "账单导出",
        showInMenu: true,
        authorizationConfig: requirePermission(OrganizationActions.BILL_READ)
      }
    },
    {
      path: "/finance/downPayRefund",
      name: "downPayRefund",
      component: () => import("@/views/finance/downPayRefund"),
      meta: {
        title: "入会费返还",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.DOWN_PAY_REFUND_READ)
      }
    },
    {
      path: "/finance/changeStore",
      name: "changeStoreList",
      component: () => import("@/views/finance/changeStoreList"),
      meta: {
        title: "换铺押金结算",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.CHANGE_STORE_READ)
      }
    },
    {
      path: "/finance/continueRent",
      name: "continueRentList",
      component: () => import("@/views/finance/continueRentList"),
      meta: {
        title: "续租押金结算",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.CONTINUE_RENT_READ)
      }
    },
    {
      path: "/finance/changeStore/viewDetail",
      name: "changeStoreDetailView",
      component: () => import("@/views/finance/changeStoreDetail/changeStoreDetail"),
      meta: {
        title: "换铺详情",
        authorizationConfig: requirePermission(FacilityActions.CHANGE_STORE_READ)
      }
    },
    {
      path: "/finance/changeStore/editDetail",
      name: "changeStoreDetailEdit",
      component: () => import("@/views/finance/changeStoreDetail/changeStoreDetail"),
      meta: {
        title: "账单调整",
        authorizationConfig: requirePermission(FacilityActions.CHANGE_STORE_EDIT)
      }
    },
    {
      path: "/finance/continueRent/viewDetail",
      name: "continueRentDetailView",
      component: () => import("@/views/finance/changeStoreDetail/changeStoreDetail"),
      meta: {
        title: "续租详情",
        authorizationConfig: requirePermission(FacilityActions.CONTINUE_RENT_READ)
      }
    },
    {
      path: "/finance/continueRent/editDetail",
      name: "continueRentDetailEdit",
      component: () => import("@/views/finance/changeStoreDetail/changeStoreDetail"),
      meta: {
        title: "账单调整",
        authorizationConfig: requirePermission(FacilityActions.CONTINUE_RENT_EDIT)
      }
    },
    {
      path: "/finance/jdexceltool",
      name: "jdExcelTool",
      component: () => import("@/views/finance/jdExcelTool"),
      meta: {
        title: "生成金蝶上传工具",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.FACILITY_READ)
      }
    },
    {
      path: "/finance/financeFee",
      name: "financeFee",
      component: () => import("@/views/finance/financeFee"),
      meta: {
        title: "客户费用明细",
        authorizationConfig: requirePermission(FacilityActions.FEES_READ)
      }
    },
    {
      path: "/finance/financeFee/financeFeeDetail",
      name: "financeFeeDetail",
      component: () =>
        import("@/views/finance/financeFeeDetail/financeFeeDetail"),
      meta: {
        title: "客户费用详情",
        authorizationConfig: requirePermission(FacilityActions.FEES_READ)
      }
    },
    {
      path: "/finance/financeFee/financeFeeNew",
      name: "financeFeeNew",
      component: () => import("@/views/finance/financeFeeDetail/financeFeeNew"),
      meta: {
        title: "新建客户费用",
        authorizationConfig: requirePermission(FacilityActions.FEES_CREATE)
      }
    },
    {
      path: "/finance/finSettlement",
      name: "finSettlement",
      component: () => import("@/views/finance/finSettlement"),
      meta: {
        title: "离场结算账单",
        keepAlive: true,
        authorizationConfig: requirePermission(
          FacilityActions.TERMINATION_BILL_READ
        )
      }
    },
    {
      path: "/finance/depositRecordList",
      name: "depositRecord",
      component: () => import("@/views/finance/depositRecord"),
      meta: {
        title: "定金记录",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.FACILITY_READ)
      }
    },
    {
      path: "/finance/licenseeBalance",
      name: "licenseeBalance",
      component: () => import("@/views/finance/licenseeBalance"),
      meta: {
        title: "商户余额",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.LIST_LICENSEE_BALANCE)
      }
    },
    {
      path: "/finance/utilityBalance",
      name: "utilityBalance",
      component: () => import("@/views/finance/utilityBalance"),
      meta: {
        title: "水电余额",
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.LIST_UTILITY_BALANCE)
      }
    },
    {
      path: "/finance/licenseeBalanceDetail",
      name: "licenseeBalanceDetail",
      component: () => import("@/views/finance/licenseeBalanceDetail"),
      props: true,
      meta: {
        title: "商户余额详情",
        authorizationConfig: requirePermission(FacilityActions.VIEW_LICENSEE_BALANCE_DETAIL)
      }
    },
    {
      path: "/finance/utilityBalanceDetail",
      name: "utilityBalanceDetail",
      component: () => import("@/views/finance/utilityBalanceDetail"),
      props: true,
      meta: {
        title: "水电余额详情",
        authorizationConfig: requirePermission(FacilityActions.VIEW_UTILITY_BALANCE_DETAIL)
      }
    }
  ]
};

export default FINANCE_ROUTES;
